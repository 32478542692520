<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.5.75v9h9A9,9,0,0,0,13.5.75Z" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.5,14.25v-9a9,9,0,1,0,5.561,16.077Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M22.5,12.75h-9l5.561,7.077A8.986,8.986,0,0,0,22.5,12.75Z"
    />
  </svg>
</template>
